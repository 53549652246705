module.exports = {
  parseParams: function (data) {
    let parsedUrl = Object.entries(data).map(e => e.join('=')).join('&')
    for (let key in data.filters) {
      parsedUrl += '&filter[' + key + ']=' + data.filters[key]
    }
    return parsedUrl
  },
  getAlphabets: function () {
    let alphabets = []
    alphabets.push({ text: '', value: '' })
    for (let a = 1; a <= 26; a++) {
      let item = {
        text: String.fromCharCode(64 + a),
        value: a
      }
      alphabets.push(item)
    }
    return alphabets
  }
}
